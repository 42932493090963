import React, { Component } from "react";
import styled from 'styled-components';
import { graphql } from "gatsby";
import get from "lodash/get";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import FullWidthImage from "../components/core/components/FullWidthImage";
import TextImageBlock from "../components/aboutus/components/TextImageBlock";
import { Container, Row, Col } from 'styled-bootstrap-grid';
import fonts from '../styles/fonts'
import colors from "../styles/colors";
import RegisterInterest from "../components/core/components/RegisterInterest";
import NewsLetterSignUp from "../components/core/components/NewsLetterSignUp";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import BlockTextImage from "../components/core/components/BlockTextImage";


const WhiteBackground = styled(Container)`
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 1440px;
  margin: auto;
`;

const RowStyled = styled(Row)`
    max-width: 1440px;
    margin: auto;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    @media(min-width: 768px) {
      padding-left: 90px;
      padding-right: 90px;
    }
`;

const AboutUsTextContainer = styled.div`
    height: 100%;
    box-sizing: border-box;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: ${props => props.padding && '30px'};
    padding-right: ${props => props.padding && '30px'};
    padding-top: 45px;
    box-sizing: border-box;
    @media(min-width: 768px) {
      padding-top: 55px;
      padding-left: ${props => props.padding && '90px'};
      padding-right: ${props => props.padding && '90px'};
    }
`;

const AboutUsBlockTitle = styled.div`
    color: #555555;
    ${fonts.MontserratBold};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
`;

const BorderLineDiv = styled.div`
    height: 4px;
    width: 117px;
    background: #ADE100;
    margin-top: 20px;
    margin-bottom: 25px;
    @media(min-width: 992px) {
        margin-top: 35px;
        margin-bottom: 40px;
    }
`;

const AboutUsDescription = styled.div`
    color: ${colors.grey};
   ${fonts.CormorantGaramondLight};
   max-width: 625px;
   padding-bottom: 45px;
   font-size: 40px;
    line-height: 48px;
   @media(min-width: 768px) {
    padding-bottom: 55px;
    font-size: 50px;
    line-height: 58px;
   }
`;

const AboutUsPageIntro = styled.div`
  ${fonts.CormorantGaramondLight};
  color: ${colors.black};
  font-size: 30px;
  line-height: 38px;
  padding-left: 0px;
  padding-bottom: 45px;
  @media(min-width: 768px) {
    padding-left: 90px;
    padding-bottom: 45px;
    
  }
`;


class TeamPage extends Component {
  render() {
    const path = this.props.location.pathname;
    const page = get(this, 'props.data.page');
    const registrationOptions = [{ region: 'Worldwide', description: 'Featuring 500+ fresh and pantry products, delivered by freight to land or shore worldwide.', link: '' }, { region: 'Mediterranean', description: 'Featuring 500+ fresh and pantry products, delivered by road to Mediterranean regions.', link: '' }];

    let pageContent = [];
    let blocks = page.blocks;

    if (blocks && blocks.length > 0) {
      blocks.forEach((block) => {
        let blockType = block.__typename;

        if (blockType === 'ContentfulBlockIntro') {
          pageContent.push(
            <RowStyled key={block.id}>
              <Col md={"5"}>
                <AboutUsDescription>{block.title}</AboutUsDescription>
              </Col>
              <Col md={"7"}>
                <AboutUsPageIntro>{documentToReactComponents(block.content.json)}</AboutUsPageIntro>
              </Col>
            </RowStyled>
          );
        }

        if (blockType === 'ContentfulBlockTextImage') {
          pageContent.push(<BlockTextImage key={block.id}
                                           image={block.image.fluid}
                                           content={documentToReactComponents(block.content.json)}
                                           type={block.imagePosition === true && 'imgFirst'}/>);
        }

        if (blockType === 'ContentfulBlockTeamMember') {
          pageContent.push(<TextImageBlock key={block.id}
                                           image={block.image.fluid}
                                           name={block.name}
                                           position={block.position}
                                           content={documentToReactComponents(block.content.json)}
                                           type={block.imagePosition === true && 'imgFirst'}/>);
        }
      });
    }
    
    return (
      <Layout>
        <SEO title="Green Chilli"
          description=""
          fullTitle={true}
          path={path} />
        <WhiteBackground>
          <FullWidthImage img={page.heroImage.fluid} />
          <AboutUsTextContainer padding>
            <AboutUsBlockTitle>Meet the team</AboutUsBlockTitle>
            <BorderLineDiv />
          </AboutUsTextContainer>
          {pageContent}
          <RegisterInterest registrationOptions={registrationOptions}/>
          <NewsLetterSignUp />
        </WhiteBackground>
      </Layout>
    );
  }
};

export default TeamPage;

export const pageQuery = graphql`
  query TeamPageQuery {
    page: contentfulPage(slug: {eq: "team"}) {
      id
      slug
      title
      metaDescription
      heroImage: heroImage {
        fluid(
          maxWidth: 1920
          quality: 70
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      blocks {
        __typename
        ... on Node {
          ... on ContentfulBlockIntro {
            id
            title
            content {
              json
            }
          }
          ... on ContentfulBlockTextImage {
            id
            imagePosition
            content {
              json
            }
            image: image {
              fluid(
                maxWidth: 1000
                quality: 70
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          ... on ContentfulBlockTeamMember {
            id
            imagePosition
            name
            position
            content {
              json
            }
            image: image {
              fluid(
                maxWidth: 1000
                quality: 70
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
