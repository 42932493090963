import React, { Component } from "react";
import styled from 'styled-components';
import fonts from '../../../styles/fonts';
import colors from "../../../styles/colors";
import { Container, Row, Col } from "styled-bootstrap-grid";
import TermsAndPrivacyAgreement from "./TermsAndPrivacyAgreement";


const SignUpForm = styled.div`
    background: transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 840px;
    margin: auto;
`;

const ContainerStyled = styled(Container)`
    position: relative;
`;

const StyledInput = styled.input`
    background-color: ${colors.white} !important;    
    ${fonts.MontserratMedium};
    border-radius: 0;
    border: 1px solid ${colors.borderGrey};
    color: ${colors.grey};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.8px;
    padding-left: 15px;
    width: 100%;
    height: 46px;
    box-sizing: border-box;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
         ${fonts.MontserratMedium};
         color: ${colors.grey};
         font-size: 12px;
         line-height: 15px;
         letter-spacing: 1.8px;
        //  padding-left: 15px;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        ${fonts.MontserratMedium};
        color: ${colors.grey};
        font-size: 12px;
        line-height: 46px;
        letter-spacing: 1.8px;
        // padding-left: 15px;
      }
      :-ms-input-placeholder { /* IE 10+ */
        ${fonts.MontserratMedium};
        color: ${colors.grey};
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 1.8px;
        // padding-left: 15px;
      }
      :-moz-placeholder { /* Firefox 18- */
        ${fonts.MontserratMedium};
        color: ${colors.grey};
        font-size: 12px;
        line-height: 46px;
        letter-spacing: 1.8px;
        // padding-left: 15px;
      }
      :-webkit-autofill,
      :-webkit-autofill:hover, 
      :-webkit-autofill:focus, 
      :-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 24px ${colors.white} inset !important;
      }
      :-webkit-autofill {
        -webkit-text-fill-color: ${colors.grey} !important;
        ${fonts.MontserratMedium};
        color: ${colors.grey};
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 1.8px;
        padding-left: 15px;
      }
    }
`;

const ColStyled = styled(Col)`
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 5px;
    padding-top: 5px;
    box-sizing: border-box;
    @media(min-width: 768px) {
        padding-left: 5px;
        padding-right: 5px;
    }
`;

const RowStyled = styled(Row)`
    width: 100%;
`;

const SubmitButton = styled.button`
    cursor: pointer;
    background-color: ${colors.white};
    border: 1px solid ${colors.green};
    box-sizing: border-box;
    ${fonts.MontserratMedium};
    height: 45px;
    width: 100%;
    text-decoration: none;
    color: ${colors.green};
    font-size: 12px;
    line-height: 15px;
    letter-saocing: 1.8px;
    text-transform: uppercase;
    transition-duration: 0.5s;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    &:hover {
        color: ${colors.white};
        background-color: ${colors.green};
      }
    :disabled {
        cursor: default;
        color: ${colors.white};
        background-color: ${colors.green};
        &:hover {
            color: ${colors.white};
            background-color: ${colors.green};
          }

    }
    @media(min-width: 768px) {
        width: 117px;
    }
`;

const SignUpTitle = styled.div`
    ${fonts.CormorantGaramondItalic};
    color: ${colors.white};
    font-size: 40px;
    line-height: 48px;
    padding-bottom: 15px;
`;

const NewsletterSignUpSubtitle = styled.div`
    ${fonts.MontserratRegular};
    font-size: 13px;
    color: ${colors.white};
    text-align: left;
    line-height: 23px;
    margin-bottom: 45px;
`;

const ErrorMessage = styled.div`
    ${fonts.MontserratRegular};
    font-size: 10px;
    color: ${colors.red};
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
    position: absolute;
    bottom : -40px;
    left: 30px;
    right: 30px;
    @media(min-width: 768px) {
        bottom : -20px;
        left: 5px;
    }
`;


class SimpleForm extends Component {
    state = {
        email: '',
        name: ''
      }

      submit = () =>
      this.state.email &&
      this.state.email.indexOf("@") > -1 &&
      this.props.onSubmitted({
        EMAIL: this.state.email,
        FNAME: this.state.name
      });

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.submit();
        }
    };
    render () {
        const { status, message } = this.props;
        const { handleKeyPress, submit } = this;

        return (
            <SignUpForm>
                <ContainerStyled>
                {status === 'success' ? 
                <>
                    <SignUpTitle>Success!</SignUpTitle>
                    <NewsletterSignUpSubtitle>Thank you for subscribing.</NewsletterSignUpSubtitle>
                </> 
                : 
                    <RowStyled>
                        <ColStyled>
                            <SignUpTitle>Sign up to our news</SignUpTitle>
                        </ColStyled>
                        <ColStyled md={5}>
                        <StyledInput
                             onKeyPress={handleKeyPress}
                             onChange={(event) => this.setState({ name: event.target.value })} 
                            type="text"
                            placeholder="Name" />
                        </ColStyled>
                        <ColStyled md={5}>
                            <StyledInput
                                 onKeyPress={handleKeyPress}
                                 onChange={(event) => this.setState({ email: event.target.value })} 
                                type="email"
                                placeholder="Your email address" />
                        </ColStyled>
                        <ColStyled md={2}>
                            <SubmitButton aria-label="Send" onClick={submit}>Send</SubmitButton>
                        </ColStyled>
                        {status === "error" && 
                    <ErrorMessage
                    dangerouslySetInnerHTML={{ __html: message }}
                    />}
                     <TermsAndPrivacyAgreement type="image"/>
                    </RowStyled> }
            
                </ContainerStyled>
            </SignUpForm>
        );
    }
};

export default SimpleForm;

