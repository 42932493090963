import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import fonts from '../../../styles/fonts';
import colors from '../../../styles/colors';

const RowStyled = styled(Row)`
    max-width: 1600px;
    margin: auto;
`;

const TextContainer = styled.div`
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 45px;
    padding-bottom: 45px;
    max-width: 455px;
    @media(min-width: 768px) {
        padding-left: 90px;
        padding-right: 90px;
        padding-top: 75px;
        padding-bottom: 60px;
        max-width: 100%;
    }
`;

const StyledImg =styled(Img)`
    width: 100%;
    height: 375px;
    @media(min-width: 768px) {
        height: 100%;
    }
`;

const TextContainerTitle = styled.div`
    ${fonts.MontserratBold};
    color: ${colors.grey};
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 3.6px;
    text-transform: uppercase;
`;

const BorderLineDiv = styled.div`
    height: 4px;
    width: 117px;
    background: ${colors.green};
    margin-top: 20px;
    margin-bottom: 17.5px;
`;

const TextContainerSubtitle = styled.div`
    ${fonts.MontserratMediumItalic};
    color: ${colors.grey};
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
`;

const TextContainerDescription = styled.div`
    ${fonts.CormorantGaramondLight};
    color: ${colors.black};
    font-size: 30px;
    line-height: 38px;
    
    blockquote {
    //   quotes: "“" "”" "‘" "’";
      position: relative;
      ${fonts.CormorantGaramondLightItalic};
      color: ${colors.grey};
      font-size: 30px;
      line-height: 40px; 
      padding-bottom: 50px;
      
      p {
        display: inline;
        text-align: left;
      }     
    }
    
    blockquote:before {
      position: absolute;
      content: open-quote;
      margin-left: -0.4em;
      margin-top: -0.2em;
    }
    
    blockquote:after {
      position: absolute;
      content: close-quote;
    }
`;


const TextImageBlock = (props) => {
    const {name, position, content, image, type} = props;
    return (
        <Container>
            <RowStyled>
                    <Col md={6} order={type === 'imgFirst' ? 'first' : 'last'} smOrder={type === 'imgFirst' ? 'first' : 'last'} mdOrder={'first'}>
                        {type === 'imgFirst' ?
                            <StyledImg fluid={image}/> 
                            : 
                            <TextContainer>
                                <TextContainerTitle>{name}</TextContainerTitle>
                                <BorderLineDiv/>
                                <TextContainerSubtitle>{position}</TextContainerSubtitle>
                                <TextContainerDescription>{content}</TextContainerDescription>
                            </TextContainer>}
                    </Col>
                    <Col md={6} order={type === 'imgFirst' ? 'last' : 'first'} smOrder={type === 'imgFirst' ? 'last' : 'first'} mdOrder={'last'}>
                        {type === 'imgFirst' ?
                            <TextContainer>
                            <TextContainerTitle>{name}</TextContainerTitle>
                            <BorderLineDiv/>
                            <TextContainerSubtitle>{position}</TextContainerSubtitle>
                            <TextContainerDescription>{content}</TextContainerDescription>
                        </TextContainer>
                            : 
                            <StyledImg fluid={image}/> 
                            }
                    </Col>
                </RowStyled>
        </Container> 
    )
}

export default TextImageBlock;
