import React from 'react';
import styled from 'styled-components';
import SignUpBackground from '../../../resources/img/sign-up.jpg';
import MailchimpSignUp from './MailchimpSignUp';


const SignUpContainer = styled.div`
    width: 100%;
    height: 330px;
    max-width: 1440px;
    margin-top: 25px;
    background: url(${SignUpBackground}) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @media(min-width: 768px) {
        margin-top: 50px;
        height: 220px;
    }
`;



const url = "https://greenchilli.us4.list-manage.com/subscribe/post?u=6889ac4c1d10d2c8742b76703&amp;id=9597dc0a75";

const NewsLetterSignUp = () => {
    return (
        <SignUpContainer>
            <MailchimpSignUp url={url} />
        </SignUpContainer>
    )
};

export default NewsLetterSignUp;
