import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import fonts from '../../../styles/fonts';
import colors from '../../../styles/colors';

const RowStyled = styled(Row)`
    margin-left: 0px;
    margin-right: 0px; 
    max-width: 1600px;
    margin: auto;
`;

const ColStyled = styled(Col)`
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TextContainer = styled.div`
    color: ${colors.grey};
    font-size: 13px;
    line-height: 23px;
    ${fonts.MontserratRegular};
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 45px;
    padding-bottom: 45px;
    max-width: 455px;
    max-height: 100%;
    @media(min-width: 768px) {
      padding-left: 90px;
      padding-right: 90px;
      padding-top: 55px;
      padding-bottom: 55px;
    }
    
    p {
      margin-bottom: 25px;
      :last-child {
        margin-bottom: 0px;
      }
      a {
        color: ${colors.green};
        font-size: 13px;
        line-height: 23px;
        ${fonts.MontserratMedium};
        text-decoration: underline;
      }
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }
    
    b {
      ${fonts.MontserratBold};
    }
    
    blockquote {
      // quotes: "“" "”" "‘" "’";
      position: relative;
      ${fonts.CormorantGaramondLightItalic};
      color: ${colors.grey};
      font-size: 40px;
      line-height: 48px; 
      max-width: 450px;
      padding-top: 45px;
      padding-bottom: 45px;
      @media(min-width: 768px) {
        padding-top: 55px;
        padding-bottom: 55px;
        font-size: 50px;
        line-height: 58px; 
      }
      
      p {
        display: inline;
        text-align: left;
      }     
    }
    
    blockquote:before {
      position: absolute;
      content: open-quote;
      margin-left: -0.4em;
      margin-top: -0.2em;
    }
    
    blockquote:after {
      position: absolute;
      content: close-quote;
    }
    
    @media(min-width: 768px) {
        padding-left: 90px;
        padding-right: 90px;
        padding-top: 75px;
        padding-bottom: 60px;
        max-width: 100%;
    }
`;

const StyledImg =styled(Img)`
    width: 100%;
    height: 375px;
    @media(min-width: 768px) {
        height: 100%;
    }
`;



const BlockTextImage = (props) => {
    const {content, image, type} = props;
    return (
      <Container>
        <RowStyled>
           <ColStyled md={6} order={type === 'imgFirst' ? 'first' : 'last'} smOrder={type === 'imgFirst' ? 'first' : 'last'} mdOrder={'first'}>
                {type === 'imgFirst' ?
                    <StyledImg fluid={image}/> 
                    : 
                    <TextContainer>
                      {content}
                    </TextContainer>}
           </ColStyled>
           <ColStyled md={6} order={type === 'imgFirst' ? 'last' : 'first'} smOrder={type === 'imgFirst' ? 'last' : 'first'} mdOrder={'last'}>
                {type === 'imgFirst' ?
                  <TextContainer>
                    {content}
                  </TextContainer>
                    : 
                    <StyledImg fluid={image}/> 
                    }
           </ColStyled>
       </RowStyled>
      </Container>
       
    )
}

export default BlockTextImage;
