import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';


const FullWidthImg = styled(Img)`
    width: 100%;
    height: 350px;
`;


const FullWidthImage = (props) => {
    const {img} = props;
    return (
        <FullWidthImg fluid={img}/>
    )
}

export default FullWidthImage;
